<template>
    <section id="content">
        <div class="container">
            <div class="c-header">
                <h2>Add User</h2>
            </div>

            <div class="card">
                <div class="card-body card-padding">
                    <form @submit.prevent="saveUser">
                        <div class="row">
                            <div class="col-md-6">
                                
                                <div class="form-group">
                                    <label for="">Name</label>
                                    <input type="text" class="form-control" v-model="user.name">
                                </div>

                                <div class="form-group">
                                    <label for="">Email</label>
                                    <input type="text" class="form-control" v-model="user.email">
                                </div>

                                <div class="form-group">
                                    <label for="">Telephone</label>
                                    <vue-tel-input @onInput="onInputTelephone" mode="international" :enabledFlags="true" :enabledCountryCode="false" :disabledFormatting="true" wrapperClasses="telinput" inputId="user-telephone" placeholder="" v-model="user.telephone"></vue-tel-input>
                                </div>

                                <div class="form-group">
                                    <label for="">Country</label>
                                    <select class="form-control" v-model="user.country">
                                        <option v-for="(country, index) in $store.state.countryList" :key="index" :value="country.name">{{ country.name }}</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label for="">Region</label>
                                    <select class="form-control" v-model="user.regionId">
                                        <option v-for="(region, Rid) in getRegionsByCountry" :value="region.id" :key="Rid">{{ region.region }} Region</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label for="">Role</label>
                                    <select class="form-control" v-model="user.role">
                                        <option :value="role.slug" v-for="(role, i) in $store.state.accessLevels" :key="i">{{ role.name }}</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <button class="btn btn-success btn-icon-text" :disabled="isProcessing">
                                        <i class="zmdi zmdi-save"></i>
                                        Save User
                                    </button> 
                                </div>
                            </div>
                            <div class="col-md-6">
                                <!-- <pre>{{ getRegionsByCountry }}</pre> -->
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
export default {
    data(){
        return {
            user: {
                regionId: "",
                role: "",
                name: "",
                email: "",
                telephone: "",
                country: "Ghana",
            },
            isProcessing: false,
            regionArray: [],
        }
    },
    created(){
        this.getRegion()
    },
    methods: {
        getRegion(){
            this.$http.get("/accounts/regions").then(response => {
                this.regionArray = response.data.data
            })
        },
        onInputTelephone({ number }) {
            this.user.telephone = number.e164
        },
        saveUser(){
            this.isProcessing = true 
            this.$http.post("/users/add", this.user)
                .then(response => {
                    if (response.data.code === 200) {
                        this.$notify.success(response.data.reason)
                        this.$router.push("/users")
                    } else {
                        this.$notify.error(response.data.reason)
                    }
                    this.isProcessing = false
                })
                .catch(error => {
                    this.$notify.error(error)
                    this.isProcessing = false
                })
        }
    },
    computed: {
        getRegionsByCountry(){
            const countryRegionList = this.regionArray
            return countryRegionList.filter(r => {
                return r.country == this.user.country
            })
        }
    }
}
</script>